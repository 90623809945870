(function (win, $) {
    'use strict';
    var fp = (win.fp = typeof win.fp !== 'undefined' ? win.fp : {}),
        home = (fp.home = typeof fp.home !== 'undefined' ? fp.home : {});

    var lazyLoadSlideshow = function () {
        // Lazy load all slideshow images after the initial
        [].forEach.call(document.querySelectorAll('.cycle-slideshow img[data-src]'), function (img) {
            img.setAttribute('src', img.getAttribute('data-src'));
            img.setAttribute('srcset', img.getAttribute('data-srcset'));
            img.setAttribute('sizes', img.getAttribute('data-sizes'));
            img.onload = function () {
                img.removeAttribute('data-src');
                img.removeAttribute('data-sizes');
            };
        });
    };

    fp.home = (function () {
        return {
            init: function () {
                $('.homepage-link').addClass('selected');

                // =========================================
                // Carousel controls
                // =========================================
                var carouselWrapper = $('.slideshow');
                var carouselTarget = $('.cycle-slideshow');
                var ariaLiveRegion = $('#control-announcements');
                $(carouselTarget).cycle();
                lazyLoadSlideshow();

                $(carouselWrapper).on("mouseenter focusin", function () {
                    if (!$(carouselTarget).hasClass('hard-pause')) {
                        $(carouselTarget).cycle('pause');
                    };
                });

                $(carouselWrapper).on("mouseleave focusout", function () {
                    if (!$(carouselTarget).hasClass('hard-pause')) {
                        $(carouselTarget).cycle('resume');
                    };
                });

                $('#cycle-pause-play').click(function () {
                    if ($(carouselTarget).hasClass('hard-pause')) {
                        $(carouselTarget).removeClass('hard-pause').cycle('resume');
                        $(this).text('Pause').attr('aria-label', 'Pause Carousel');
                        $(ariaLiveRegion).text('Carousel is playing.');
                    } else {
                        $(carouselTarget).addClass('hard-pause').cycle('pause');
                        $(this).text('Play').attr('aria-label', 'Play Carousel');
                        $(ariaLiveRegion).text('Carousel is paused.');
                    }
                });

                $('#cycle-back').click(function () {
                    $(ariaLiveRegion).text('Reading previous slide.');
                });

                $('#cycle-next').click(function () {
                    $(ariaLiveRegion).text('Reading next slide.');
                });
            }
        };
    })();
})(window, $);
